import {Component, OnInit} from '@angular/core';
import {NGXLogger} from "ngx-logger";

import {NavigationService} from "../../../core/services/navigation.service";
import {PermissionStore} from "../../../redux/app.store";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit{

  navigationItems: PermissionStore[] = [];

  constructor(
    private logger: NGXLogger,
    private navigationService: NavigationService,
  ) {
  }

  ngOnInit(): void {
    this.navigationService.NavMenu$.subscribe((data: PermissionStore[]) => {
      this.navigationItems = data;
    });

    this.navigationItems = this.navigationService.getNavMenu(this.navigationService.getActiveTop()!);

  }

}
