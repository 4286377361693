import {esmaxInitialState, esmaxReducer} from "./esmax/esmax.reducer";
import {msalInitialState, msalReducer} from "./msal/msal.reducer";
import {userInitialState, userReducer} from "./user/user.reducer";
import {Action, combineReducers} from "@ngrx/store";
import {AuthStore} from "../app.store";
import {permissionsInitialState, permissionsReducer} from "./permissions/permissions.reducer";


const reducers = {
  esmax: esmaxReducer,
  msal: msalReducer,
  user: userReducer,
  permissions: permissionsReducer
}

export const authInitialState: AuthStore = {
  esmax: esmaxInitialState,
  msal: msalInitialState,
  user: userInitialState,
  permissions: permissionsInitialState
}

const rootReducer = combineReducers<AuthStore>(reducers, authInitialState);


export function authReducer(state: AuthStore | undefined, action: Action): AuthStore {
  return rootReducer(state || authInitialState, action);
}

