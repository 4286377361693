import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NGXLogger} from "ngx-logger";
import {NavigationService} from "../../../../core/services/navigation.service";
import {PermissionStore} from "../../../../redux/app.store";

@Component({
  selector: 'design-topbar-button',
  templateUrl: './topbar-button.component.html',
  styleUrls: ['./topbar-button.component.css']
})
export class TopbarButtonComponent implements OnInit {

  @Input() parent: any;
  active: boolean = false;


  constructor(
    private router: Router,
    private logger: NGXLogger,
    private navigationService: NavigationService,
  ) {
  }

  goToPath(path: PermissionStore) {
    this.navigationService.setNavMenu(path);
  }

  ngOnInit(): void {
    this.navigationService.NavMenu$.subscribe((data: PermissionStore[]) => {
      this.active = data[0].idMenuPadre === this.parent.idMenu;
    });
    this.active = this.navigationService.getActiveTop()?.idMenu === this.parent.idMenu;
  }


}
