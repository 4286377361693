import {EnvironmentModel} from "../app/core/models/environment.model";

export const environment: EnvironmentModel = {
  production: true,
  pricemax: {
    retail: 'https://pricemaxretail.esmax.cl',
    admin: 'https://pricemaxretail.esmax.cl',
  },
  api: {
    retail: 'https://azapipricemaxretailapiprod.azurewebsites.net/api',
    admin: 'https://azapipricemaxadminapiprod.azurewebsites.net/api',
  },
  azure: {
    clientId: '45540698-66a9-4e36-8bd3-da42dc186524',
    authority: 'https://login.microsoftonline.com/729f72ac-8060-4779-8ce0-60bbfefbb2e0',
    redirectUri: 'https://pricemaxretail.esmax.cl',
    postLogoutRedirectUri: 'https://pricemaxretail.esmax.cl/login'
  },
  google: {
    maps: {
      apiKey: 'AIzaSyAjJlRWZHIJB-HixpD71Ic7Hgi6sKeItq8'
    }
  }
};
