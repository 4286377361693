import {Component, Input, OnInit} from '@angular/core';
import {TokenUpdater} from "../../../core/decorators/token-updater.decorator";
import {AuthorizationService} from "../../../core/services/authorization.service";

@Component({
  selector: 'app-default-page',
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.css']
})
export class DefaultPageComponent implements OnInit{

  @Input() fullscreen:boolean = false


  constructor(
    private authorizationService: AuthorizationService,
  ) {
  }

  get getScreenClass() {
    return this.fullscreen ? '' : 'pt-4 mt-4'
  }

  get getScreenStyle() {
    return this.fullscreen ? '': 'padding-left: 50px; padding-right: 50px; padding-bottom: 8vh'
  }

  @TokenUpdater()
  ngOnInit(): void {

  }

}
