import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AppStore} from "../../../redux/app.store";
import {Store} from "@ngrx/store";
import * as esmaxActions from "../../../redux/auth/esmax/esmax.actions";

@Injectable()
export class ErrorUnautorizedInterceptor implements HttpInterceptor {

  constructor(private store: Store<AppStore>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Si recibimos un error 401, ejecutamos el método logout
          this.store.dispatch(esmaxActions.logoutEsmaxAction());
        }
        // Propagar el error para que otros puedan manejarlo también
        return throwError(error);
      })
    );
  }
}

