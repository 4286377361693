import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NGXLogger} from "ngx-logger";
import {MenuItem} from "primeng/api";
import {NavigationService} from "../../../../core/services/navigation.service";
import {PermissionStore} from "../../../../redux/app.store";

@Component({
  selector: 'design-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.css']
})
export class NavigationButtonComponent implements OnInit{

  @Input() model: PermissionStore | undefined;

  items: MenuItem[] = [];

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private navigationService: NavigationService,
  ) {

  }

  ngOnInit(): void {

  }

  goToPage(page: string): void {
    this.router.navigate([page]);
  }

  setItems(model: PermissionStore){
    const children = this.navigationService.getChildrenMenu(model);
    this.items = [];

    children.forEach((child:any) => {
      this.items?.push({label: child.menu, command: () => this.goToPage(this.navigationService.getChildrenUrl(child) ?? '')});
    });

  }

}
