import {AuthorizationService} from "../services/authorization.service";
import {NGXLogger} from "ngx-logger";

export function TokenUpdater() {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const authorizationService: AuthorizationService = Reflect.get(this, 'authorizationService');

      return originalMethod.apply(this, args);

    };

    return descriptor;
  };
}
