import {createAction, props} from "@ngrx/store";
import {TokenStore} from "../../app.store";


export const loginEsmaxAction = createAction(
  '[Auth ESMAX] Login',
  props<{ msal: TokenStore }>()
);

export const loginEsmaxSuccessAction = createAction(
  '[Auth ESMAX] Login Success',
  props<{ esmax: TokenStore }>()
);

export const loginEsmaxFailureAction = createAction(
  '[Auth ESMAX] Login Failure',
  props<{ error: string }>()
);

export const saveEsmaxAction = createAction(
  '[Auth ESMAX] Save',
  props<{ esmax: TokenStore }>()
);

export const savedEsmaxAction = createAction(
  '[Auth ESMAX] Saved'
);

export const loadEsmaxAction = createAction(
  '[Auth ESMAX] Load'
);

export const loadEsmaxSuccessAction = createAction(
  '[Auth ESMAX] Load Success',
  props<{ esmax: TokenStore }>()
);

export const loadEsmaxFailureAction = createAction(
  '[Auth ESMAX] Load Failure',
  props<{ error: string }>()
);

export const logoutEsmaxAction = createAction(
  '[Auth ESMAX] Logout'
);

export const outEsmaxAction = createAction(
  '[Auth ESMAX] Out'
);
