import {Inject, Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {AuthenticationResult, Configuration, InteractionType, PopupRequest} from "@azure/msal-browser";
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration, MsalService
} from "@azure/msal-angular";
import {AuthorizationService} from "./authorization.service";
import {MsAuthorizationModel} from "../models/ms-authorization.model";
import {Observable, Subject} from "rxjs";
import {AppStore, TokenStore} from "../../redux/app.store";
import {Store} from "@ngrx/store";
import {StorageService} from "./storage.service";

@Injectable({
  providedIn: 'root'
})
class MsAuthService {

  onError: Subject<any> = new Subject<any>();

  private azureEnv: {
    clientId: string,
    authority: string,
    redirectUri: string,
    postLogoutRedirectUri: string
  } = environment.azure;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
  ) {
  }

  login$():  Observable<AuthenticationResult> {
    sessionStorage.removeItem('msal.interaction.status');

    if (!this.msalGuardConfig.authRequest) {
      this.msalGuardConfig.authRequest = {
        scopes: ['user.read'],
      }
      return new Observable<AuthenticationResult>();
    }
    return this.msalService.loginPopup({
      ...this.msalGuardConfig.authRequest as PopupRequest
    }) || new Observable<AuthenticationResult>();
  }

  logout$():Observable<void> {
    return this.msalService.logout({
      postLogoutRedirectUri: this.azureEnv.postLogoutRedirectUri,
    });
  }

}

const azureConfig: Configuration = {
  auth: {
    clientId: environment.azure.clientId,
    authority: environment.azure.authority,
    redirectUri: environment.azure.redirectUri,
    postLogoutRedirectUri: environment.azure.postLogoutRedirectUri
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      window.navigator.userAgent.indexOf('Trident/') > -1,
  },
}

const azureGuardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: ['user.read'],
  },
}

const azureInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
  protectedResourceMap: new Map([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
  ]),
}

export {MsAuthService, azureConfig, azureGuardConfig, azureInterceptorConfig};
