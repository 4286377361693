import {TokenStore} from "../../app.store";
import {Action, createReducer, on} from "@ngrx/store";
import * as msalActions from "./msal.actions";


export const msalInitialState: TokenStore = {
  token: null,
  isExpired: true,
  dateExpired: null,
  name: null
}

const reducer = createReducer(
  msalInitialState,
  on(msalActions.loginMsalAction, (state) => msalInitialState),
  on(msalActions.loginMsalSuccessAction, (state, {msal}) => ({...state, ...msal})),
  on(msalActions.loginMsalFailureAction, (state) => msalInitialState),
  on(msalActions.saveMsalAction, (state) => state),
  on(msalActions.loadMsalAction, (state) => state),
  on(msalActions.loadMsalSuccessAction, (state, {msal}) => ({...state, ...((state.token === null || state.token === '') ? msal : state)})),
  on(msalActions.loadMsalFailureAction, (state) => msalInitialState),
  on(msalActions.logoutMsalAction, (state) => state),
  on(msalActions.outMsalAction, (state) => msalInitialState),
);

export function msalReducer(state: TokenStore | undefined, action: Action) {
  return reducer(state, action);
}
