<div class="bg-login"></div>

<div class="body-login flex flex-column align-items-center justify-content-center">
  <div>
    <h1 class="text-center">PriceMax</h1>
    <div class="flex w-full justify-content-center align-items-center">
      <img src="../../../../assets/images/logo-esmax.png" alt="logo-esmax" class="m-0 p-0" style="height: 60px">
      <h2>Esmax</h2>
    </div>

    <div>
      <button *ngIf="!isLoading" class="btn-login mt-8 flex align-items-center justify-content-center" (click)="signUp()">
        <img src="../../../../assets/images/logo-ms.png" [style]="{'height': '3.5rem', 'margin-left':'0.5rem'}">
        <p *ngIf="(msal$ | async)?.name === null" class="text-white ml-3">Iniciar Sesión con Microsoft</p>
        <p *ngIf="(msal$ | async)?.name !== null" class="text-white ml-3">Ingresar como {{(msal$ | async)?.name}}</p>
      </button>
      <button *ngIf="!isLoading && (msal$ | async)?.name !== null" class="btn-logout mt-3 flex align-items-center justify-content-center" (click)="logout()">
        <p [style]="{'font-size': '3.5rem'}" class="pi pi-sign-out my-0 ml-2"></p>
        <p class="text-white w-full text-center ml-3">Cerrar Cuenta</p>
      </button>
      <div class="flex justify-content-center align-items-center w-full mt-6">

        <i *ngIf="isLoading" class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
      </div>
    </div>
  </div>

</div>

<p-toast></p-toast>
