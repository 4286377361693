import {Component, OnInit} from '@angular/core';
import {MegaMenuItem, MenuItem} from "primeng/api";
import {NGXLogger} from "ngx-logger";
import {AppStore, PermissionStore, UserStore} from "../../../redux/app.store";
import {Store} from "@ngrx/store";
import {map, Observable} from "rxjs";
import {ApiService} from "../../../core/api/api.service";
import * as esmaxActions from "../../../redux/auth/esmax/esmax.actions";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit{

  items: MenuItem[];

  topbarItems: Observable<readonly PermissionStore[]> = this.store.select(state => state.auth.permissions).pipe(
    map((data: readonly PermissionStore[]) => {
      return data.filter((item: PermissionStore) => item.nivel === 1);
    })
  );
  account_image_default = 'https://asr.esmax.cl/assets/logo-8016544e9bcde9e00fb0d427bbe3551d2a911b925e577ee41ceae915ca98048d.png';

  account:Observable<UserStore> = this.store.select(state => state.auth.user);

  constructor(
    private logger: NGXLogger,
    private apiService: ApiService,
    private store: Store<AppStore>
  ) {

    this.items = [
      {
        label: 'Token',
        icon: 'pi pi-fw pi-cog',
        command: () => {
          navigator.clipboard.writeText(`Bearer ${this.apiService.token}`)
            .then(() => {
              console.log('Token copiado al portapapeles')
            })
            .catch(err => {
              console.error('Error al copiar al portapapeles:', err)
            })
        }
      },
      {
        label: 'Cerrar Sesión',
        icon: 'pi pi-fw pi-power-off',
        command: () => {
          this.store.dispatch(esmaxActions.logoutEsmaxAction());
        }
      }
    ];

  }

  ngOnInit(): void {
  }


}
