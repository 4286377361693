import {createAction, props} from "@ngrx/store";
import {TokenStore} from "../../app.store";


export const loginMsalAction = createAction(
  '[Auth MSAL] Login'
);

export const loginMsalSuccessAction = createAction(
  '[Auth MSAL] Login Success',
  props<{ msal: TokenStore }>()
);

export const loginMsalFailureAction = createAction(
  '[Auth MSAL] Login Failure',
  props<{ error: string }>()
);

export const saveMsalAction = createAction(
  '[Auth MSAL] Save',
  props<{ msal: TokenStore }>()
);

export const savedMsalAction = createAction(
  '[Auth MSAL] Saved'
);

export const loadMsalAction = createAction(
  '[Auth MSAL] Load'
);

export const loadMsalSuccessAction = createAction(
  '[Auth MSAL] Load Success',
  props<{ msal: TokenStore }>()
);

export const loadMsalFailureAction = createAction(
  '[Auth MSAL] Load Failure',
  props<{ error: string }>()
);

export const logoutMsalAction = createAction(
  '[Auth MSAL] Logout'
);

export const outMsalAction = createAction(
  '[Auth MSAL] Out'
);
