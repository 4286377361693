import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as permissionsActions from "./permissions.actions";
import {catchError, map, mergeMap, of, switchMap} from "rxjs";
import {AppStore, PermissionStore, TokenStore} from "../../app.store";
import {Store} from "@ngrx/store";
import {StorageService} from "../../../core/services/storage.service";

@Injectable()
export class PermissionsEffects {

  constructor(
    private actions$: Actions,
    private storageService: StorageService,
    private store: Store<AppStore>
  ) {
  }

  registerPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(permissionsActions.registerPermissionsAction),
      switchMap(({permissions}) => {
        return [
          permissionsActions.savePermissionsAction({permissions}),
        ];
      })
    ));

  savePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(permissionsActions.savePermissionsAction),
      switchMap(({permissions}) => {
        this.storageService.save('permissions', permissions);
        return [
          permissionsActions.savedPermissionsAction(),
        ];
      })
    ));

  loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(permissionsActions.loadPermissionsAction),
      switchMap(() => {
        const permissions = this.storageService.get<PermissionStore[]>('permissions');
        if(permissions) {
          return [
            permissionsActions.loadPermissionsSuccessAction({permissions: permissions!}),
          ];
        }else{
          return [
            permissionsActions.loadPermissionsFailureAction({error: 'No se encontraron permisos'}),
          ];
        }
      })
    ));

}
