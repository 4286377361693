import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {DefaultPageComponent} from "./design/layout/default-page/default-page.component";
import {LoginPageComponent} from "./design/views/login-page/login-page.component";
import {DashboardComponent} from "./design/views/dashboard/dashboard.component";
const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'station',
    component: DefaultPageComponent,
    loadChildren: () => import('./modules/station/station.module').then(m => m.StationModule),
  },
  {
    path: 'dashboard-rp',
    component: DefaultPageComponent,
    loadChildren: () => import('./modules/dashboard-rp-com/dashboard-rp-com.module').then(m => m.DashboardRpComModule),
  },
  {
    path: 'letters',
    component: DefaultPageComponent,
    loadChildren: () => import('./modules/letter/letter.module').then(m => m.LetterModule),
  },
  {
    path: 'escalation',
    component: DefaultPageComponent,
    loadChildren: () => import('./modules/escalation/escalation.module').then(m => m.EscalationModule),
  },
  {
    path: 'dashboard-reseller',
    component: DefaultPageComponent,
    loadChildren: () => import('./modules/dashboard-reseller/dashboard-reseller.module').then(m => m.DashboardResellerModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [],
  declarations: [],
})
class AppRoutingModule {
}

export {AppRoutingModule, appRoutes};
