import {TokenStore} from "../../app.store";
import {Action, createReducer, on} from "@ngrx/store";
import * as esmaxActions from "./esmax.actions";


export const esmaxInitialState: TokenStore = {
  token: null,
  isExpired: true,
  dateExpired: null,
  name: null
}

const reducer = createReducer(
  esmaxInitialState,
  on(esmaxActions.loginEsmaxAction, (state) => esmaxInitialState),
  on(esmaxActions.loginEsmaxSuccessAction, (state, {esmax}) => ({...state, ...esmax})),
  on(esmaxActions.loginEsmaxFailureAction, (state) => esmaxInitialState),
  on(esmaxActions.saveEsmaxAction, (state) => state),
  on(esmaxActions.savedEsmaxAction, (state) => state),
  on(esmaxActions.loadEsmaxAction, (state) => state),
  on(esmaxActions.loadEsmaxSuccessAction, (state, {esmax}) =>({...state, ...((state.token === null || state.token === '') ? esmax : state)})),
  on(esmaxActions.loadEsmaxFailureAction, (state) => esmaxInitialState),
  on(esmaxActions.logoutEsmaxAction, (state) => state),
  on(esmaxActions.outEsmaxAction, (state) => esmaxInitialState)
);

export function esmaxReducer(state: TokenStore | undefined, action: Action) {
  return reducer(state, action);
}
