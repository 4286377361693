import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as esmaxActions from "./esmax.actions";
import * as userActions from "../user/user.actions";
import * as permissionsActions from "../permissions/permissions.actions";
import {catchError, map, mergeMap, of, switchMap} from "rxjs";
import {TokenStore} from "../../app.store";
import {AuthorizationService} from "../../../core/services/authorization.service";
import {StorageService} from "../../../core/services/storage.service";
import {NotificationService} from "../../../core/services/notification.service";
import {TypeNotification} from "../../../core/models/type-notification.enum";

@Injectable()
export class EsmaxEffects {

  constructor(
    private actions$: Actions,
    private authorizationService: AuthorizationService,
    private storageService: StorageService,
    private notificationService: NotificationService,
  ) {
  }

  loginEsmax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(esmaxActions.loginEsmaxAction),
      switchMap(({msal}) =>
        this.authorizationService.login$(msal).pipe(
          mergeMap((login) => [
            esmaxActions.loginEsmaxSuccessAction({
              esmax: {
                token: login.token,
                dateExpired: null,
                isExpired: false,
                name: login.name
              }
            }),
            userActions.registerUserAction({
              user: {
                userId: login.userId,
                name: login.name,
                urlAvatar: login.urlAvatar,
                roles: login.roles,
                cargo: login.cargo
              }
            }),
            permissionsActions.registerPermissionsAction({
              permissions: login.permissions.map((permission) => ({
                idMenu: permission.idMenu,
                idMenuPadre: permission.idMenuPadre || null,
                url: permission.url,
                menu: permission.menu,
                idPermiso: permission.idPermiso,
                nivel: permission.nivel,
                perfil: permission.perfil
              }))
            })
          ]),
          catchError((error: any) => {
            console.log("ERROR ESMAX",error)
            this.notificationService.setNotification({
              type: TypeNotification.ERROR,
              title: 'Error',
              message: 'No tiene permisos suficientes para iniciar sesión'
            })
            return of(esmaxActions.loginEsmaxFailureAction({error}));
          })
        ))
    ));

  loginSuccessEsmax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(esmaxActions.loginEsmaxSuccessAction),
      map(({esmax}) => {
        return esmaxActions.saveEsmaxAction({esmax});
      })
    ));

  saveEsmax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(esmaxActions.saveEsmaxAction),
      map(({esmax}) => {
        this.storageService.save('esmax', esmax);
        this.authorizationService.goToDashboard();
        return esmaxActions.savedEsmaxAction();
      })
    ));

  loadEsmax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(esmaxActions.loadEsmaxAction),
      switchMap(() => {
        const esmax = this.storageService.get<TokenStore>('esmax');
        if(esmax) {
          return [
            esmaxActions.loadEsmaxSuccessAction({esmax: esmax!}),
          ];
        }else{
          this.notificationService.setNotification({
            type: TypeNotification.ERROR,
            title: 'Error',
            message: 'No se pudo obtener el token de sesión'
          })
          return [
            esmaxActions.loadEsmaxFailureAction({error: 'No se encontró el token'}),
          ];
        }
      })
    ));

  logoutEsmax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(esmaxActions.logoutEsmaxAction),
      switchMap(() => {
        this.authorizationService.logout();
        return [
          esmaxActions.outEsmaxAction(),
        ];
      })
    ));


}
