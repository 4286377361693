import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'design-button-lg',
  templateUrl: './button-large.component.html',
  styleUrls: ['./button-large.component.css']
})
export class ButtonLargeComponent implements OnInit {

  @Input("label") label: string = "";
  @Input("icon") icon: string = "";
  @Input("design") design: string = "";
  @Input("disabled") disabled: boolean = false;

  @Output("onClick") onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
}
