import {PermissionStore} from "../../app.store";
import {createReducer, on} from "@ngrx/store";
import * as permissionsActions from "./permissions.actions";


export const permissionsInitialState: ReadonlyArray<PermissionStore> = [];

const reducer = createReducer(
  permissionsInitialState,
  on(permissionsActions.registerPermissionsAction, (state, {permissions}) => permissions),
  on(permissionsActions.savePermissionsAction, (state) => state),
  on(permissionsActions.savedPermissionsAction, (state) => state),
  on(permissionsActions.loadPermissionsAction, (state) => state),
  on(permissionsActions.loadPermissionsSuccessAction, (state, {permissions}) => {
    if(state.length === 0) return permissions;
    return state;
  }),
  on(permissionsActions.loadPermissionsFailureAction, (state) => permissionsInitialState),
  on(permissionsActions.deletePermissionsAction, (state) => state),
  on(permissionsActions.deletedPermissionsAction, (state) => permissionsInitialState),
);

export function permissionsReducer(state: ReadonlyArray<PermissionStore> | undefined, action: any) {
  return reducer(state, action);
}
