import {authInitialState, authReducer} from "./auth/auth.reducer";
import {AppStore} from "./app.store";


export const appReducers = {
  auth : authReducer,
}
export const appInitialState: AppStore = {
  auth: authInitialState,
}
