import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'design-button-sm',
  templateUrl: './button-small.component.html',
  styleUrls: ['./button-small.component.css']
})
export class ButtonSmallComponent implements OnInit {

  @Input("label") label: string = "";
  @Input("icon") icon: string = "";
  @Input("design") design: string = "";
  @Input("disabled") disabled: boolean = false;

  @Output("onClick") onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
}
