import {Injectable} from '@angular/core';
import {NavigationEnd, NavigationStart, Router, RoutesRecognized} from "@angular/router";
import {Subject} from "rxjs";
import {AuthorizationService} from "./authorization.service";
import {ROUTER_COMPONENT} from "../router/routes";
import {FormGroup} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {AppStore, PermissionStore} from "../../redux/app.store";
import {Store} from "@ngrx/store";
import * as permissionsActions from "../../redux/auth/permissions/permissions.actions";
import * as msalActions from "../../redux/auth/msal/msal.actions";
import * as esmaxActions from "../../redux/auth/esmax/esmax.actions";
import * as userActions from "../../redux/auth/user/user.actions";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  routes: PermissionStore[] = [];

  NavMenu$: Subject<PermissionStore[]> = new Subject<PermissionStore[]>();



  constructor(
    private router: Router,
    // private title: Title,
    private store: Store<AppStore>
  ) {
    this.router.events.subscribe((event) => {
      if((event instanceof NavigationStart)) {
        if(event.url === '') return;
        this.store.dispatch(msalActions.loadMsalAction());
        if(event.url === '/login') return;
        this.store.dispatch(permissionsActions.loadPermissionsAction());
        this.store.dispatch(esmaxActions.loadEsmaxAction());
        this.store.dispatch(userActions.loadUserAction());
      }
    });

    this.store.select(state => state.auth.permissions).subscribe((data: readonly PermissionStore[]) => {
      this.routes = data.map((item: PermissionStore) => item);
      this.chargeAuthorization();
    });

  }

  chargeAuthorization() {
    ROUTER_COMPONENT.forEach((route: any) => {
      const permission = this.routes.find((item: PermissionStore) => item.idMenu === route.id);
      if(!permission) return;
      const nav_parent = this.routes.find((item: PermissionStore) => item.idMenu === permission.idMenuPadre);
      if(!nav_parent) return;
      const top_parent = this.routes.find((item: PermissionStore) => item.idMenu === nav_parent.idMenuPadre);
      if(!top_parent) return;
      const url = top_parent.url.trim() + '/' + nav_parent.url.trim() + '/' + permission.url.trim();
      if(this.router.config.find((item: any) => item.path === url)) return;
      this.router.config.push({
        path: url,
        title: permission.menu,
        loadComponent: route.component,
      });
    });
    this.router.resetConfig(this.router.config);
  }

  getNavMenu(parent: PermissionStore | null = null) {
    if(!parent) return [];
    return this.routes.filter((item: PermissionStore) => item.idMenuPadre === parent.idMenu && item.nivel === 2);
  }

  setNavMenu(parent: PermissionStore) {
    const nav_menu = this.getNavMenu(parent);
    this.NavMenu$.next(nav_menu);
  }

  getChildrenMenu(parent: PermissionStore) {
    return this.routes.filter((item: PermissionStore) => item.idMenuPadre === parent.idMenu && item.nivel === 3);
  }

  getChildrenUrl(parent: PermissionStore | undefined) {
    if(!parent) return null;
    const nav_menu = this.routes.find((item: PermissionStore) => item.idMenu === parent.idMenuPadre && item.nivel === 2);

    if(!nav_menu) return null;

    const top_menu = this.routes.find((item: PermissionStore) => item.idMenu === nav_menu?.idMenuPadre && item.nivel === 1);

    if(!top_menu) return null;

    return '/' + top_menu?.url.trim() + '/' + nav_menu?.url.trim() + '/' + parent.url.trim();
  }

  getMenuById(id: number) {
    return this.routes.find((item: PermissionStore) => item.idMenu === id);
  }

  getActiveChild() {
    const url = this.router.url.split('/');
    return this.routes.find((item: PermissionStore) => item.url === url[3].split('?')[0] && item.nivel === 3);
  }

  getActiveTop() {
    const url = this.router.url.split('/');
    return this.routes.find((item: PermissionStore) => item.url === url[1] && item.nivel === 1);
  }

  hasPermission(nivel: number, id: number| null = null ) {
    const active = (id == null) ? this.getActiveChild() : this.getMenuById(id);
    if(active === undefined) return false;
    return active.idPermiso >= nivel;
  }

  canView(nivel: number, id: number| null = null ) {
    return this.hasPermission(nivel, id);
  }

  canEdit(id: number| null = null, form: FormGroup ) {
    if(!this.hasPermission(3, id)){
      form.disable();
      return false;
    }
    return true;
  }



}
