import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MapAuthorizationService {

  MapsLibrary!: google.maps.MapsLibrary;
  MarkerLibrary!: google.maps.MarkerLibrary;

  mapsLoaded: boolean = false;
  mapsLoadedEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.loadGoogleMaps().then(async() => {
      console.log("Loading Google Maps")
      await this.initLibrary();
      this.mapsLoaded = true;
      this.mapsLoadedEvent.next(true);
    });
  }

  loadGoogleMaps(): Promise<void> {
    return new Promise((resolve) => {
      if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
        (window as any).onGoogleMapsScriptLoad = resolve;
        const script = document.createElement('script');

        script.src = `https://maps.googleapis.com/maps/api/js?v=beta&key=${environment.google.maps.apiKey}&libraries=marker,geometry&callback=onGoogleMapsScriptLoad`;
        // script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.google.maps.apiKey}&callback=onGoogleMapsScriptLoad`;
        document.body.appendChild(script);
      } else {
        resolve();
      }
    });
  }

  async initLibrary() {
    this.MapsLibrary = (await google.maps.importLibrary(
      'maps'
    )) as google.maps.MapsLibrary;

    this.MarkerLibrary = (await google.maps.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    if (!this.MapsLibrary) throw 'Map not load';
    if (!this.MarkerLibrary) throw 'Map not load';
  }
}
