import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  save <T> (key: string, value: T): void {

    localStorage.setItem(key, JSON.stringify(value));
  }

  get <T> (key: string): T | undefined {
    if(!this.check(key)) return undefined;

    return JSON.parse(localStorage.getItem(key)!) as T;
  }

  delete(key: string): void {
    localStorage.removeItem(key);
  }

  check(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

}
