import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as msalActions from "./msal.actions";
import * as esmaxActions from "../esmax/esmax.actions";
import {catchError, map, of, switchMap} from "rxjs";
import {MsAuthService} from "../../../core/services/ms-auth.service";
import {AppStore, TokenStore} from "../../app.store";
import {AuthenticationResult} from "@azure/msal-browser";
import {Store} from "@ngrx/store";
import {StorageService} from "../../../core/services/storage.service";
import {Router} from "@angular/router";
import {NotificationService} from "../../../core/services/notification.service";
import {TypeNotification} from "../../../core/models/type-notification.enum";


@Injectable()
export class MsalEffects {

  constructor(
    private actions$: Actions,
    private msAuthService: MsAuthService,
    private storageService: StorageService,
    private notificationService: NotificationService,
  ) {
  }

  loginMsal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(msalActions.loginMsalAction),
      switchMap(() =>
        this.msAuthService.login$().pipe(
          map((msal: AuthenticationResult) => msalActions.loginMsalSuccessAction({
            msal: {
              token: msal.accessToken,
              dateExpired: msal.expiresOn,
              isExpired: false,
              name: msal.account?.name ?? null
            }
          })),
          catchError((error: string) =>
            {
              this.notificationService.setNotification({
                type: TypeNotification.ERROR,
                message: 'Error al iniciar sesión',
                title: 'Error'
              });
              return of(msalActions.loginMsalFailureAction({error}));
            }
          )
        ))
    ));

  loginSuccessMsal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(msalActions.loginMsalSuccessAction),
      map(({msal}) => {
        return msalActions.saveMsalAction({msal});
      })
    ));

  saveMsal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(msalActions.saveMsalAction),
      switchMap(({msal}) => {
        this.storageService.save('msal', msal);
        return [
          msalActions.savedMsalAction(),
          esmaxActions.loginEsmaxAction({msal})
        ];
      })
    ));

  loadMsal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(msalActions.loadMsalAction),
      switchMap(() => {
        const msal = this.storageService.get<TokenStore>('msal');
        if(msal) {
          return [
            msalActions.loadMsalSuccessAction({msal: msal!}),
          ];
        }else{
          return [
            msalActions.loadMsalFailureAction({error: 'No se encontraron datos de msal'}),
          ];
        }
      })
    ));

  logoutMsal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(msalActions.logoutMsalAction),
      switchMap(() => {
        return this.msAuthService.logout$().pipe(
          map(() => {
            return msalActions.outMsalAction();
          }),
        )
      })
    ));

}
