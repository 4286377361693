import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {catchError, Observable, of, share} from "rxjs";
import {environment} from "../../../environments/environment";
import {AppStore} from "../../redux/app.store";
import {Store} from "@ngrx/store";
import * as esmaxActions from "../../redux/auth/esmax/esmax.actions";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  token: string = '';

  constructor(
    private http: HttpClient,
    private store: Store<AppStore>
  ) {
    this.store.select(state => state.auth.esmax).subscribe((data) => {
      if(!data.isExpired){
        this.token = data.token!;
      }
    });
  }

  httpHeaders(): HttpHeaders {
    let headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + this.token)
      .append('Accept', '*/*')
      .append('Access-Control-Allow-Origin', 'http://localhost:4200')
      .append('Access-Control-Allow-Credentials', 'true');

    return headers;
  }


  all(url: string): Observable<any> {
    return this.http.get(environment.api.retail + url, { headers: this.httpHeaders() })
      // .pipe(this.verifyAuthorization());
  }

  allAdmin(url: string): Observable<any> {
    return this.http.get(environment.api.admin + url, { headers: this.httpHeaders() })
      // .pipe(this.verifyAuthorization());
  }

  single (url: string): Observable<any> {
    return this.http.get(environment.api.retail + url, { headers: this.httpHeaders() })
      // .pipe(this.verifyAuthorization());
  }

  post(url: string, body: any): Observable<any> {
    return this.http.post(environment.api.retail + url, body, { headers: this.httpHeaders() })
      // .pipe(this.verifyAuthorization());
  }
  postGetFile(url: string, body: any): Observable<any> {
    return this.http.post(environment.api.retail + url, body, { headers: this.httpHeaders() , responseType: 'blob' })
      // .pipe(this.verifyAuthorization());
  }


  postAdmin(url: string, body: any): Observable<any> {
    return this.http.post(environment.api.admin + url, body, { headers: this.httpHeaders() })
      // .pipe(this.verifyAuthorization());
  }


  singleFilter(url: string, filter: string): Observable<any> {
    const payload = {
      "filter": filter
    }

    return this.http.post(environment.api.retail + url, payload, { headers: this.httpHeaders() })
      // .pipe(this.verifyAuthorization());
  }

  page(url: string, page: number = 0, size: number = 5, args:any): Observable<any> {
    let payload = {
      "numPagina": page + 1,
      "cantRegPag": size,
    };

    if(args){
      payload = {
        ...payload,
        ...args
      }
    }

    return this.http.post(environment.api.retail + url, payload, { headers: this.httpHeaders() })
      // .pipe(this.verifyAuthorization());
  }

  put(url: string, body: any): Observable<any> {
    return this.http.put(environment.api.retail + url, body, { headers: this.httpHeaders()})
      // .pipe(this.verifyAuthorization());
  }

  delete(url: string): Observable<any> {
    return this.http.delete(environment.api.retail + url, { headers: this.httpHeaders() })
      // .pipe(this.verifyAuthorization());
  }

  patch(url: string, body: any): Observable<any> {
    return this.http.patch(environment.api.retail + url, body, { headers: this.httpHeaders() })
      // .pipe(this.verifyAuthorization());
  }

  verifyAuthorization() {
    return catchError((error, caught) => {
      //verificar que el error sea del tipo HttpErrorResponse
      if (error instanceof HttpErrorResponse) {
        //verificar que el error sea del tipo 401
        if (error.status === 401) {
          //si es 401, entonces redirigimos a la página de login
          this.store.dispatch(esmaxActions.logoutEsmaxAction());
        }
      }
      return of(error);
    })
  }


}
