import {createAction, props} from "@ngrx/store";
import {PermissionStore} from "../../app.store";


export const registerPermissionsAction = createAction(
  '[Auth] Register Permissions',
  props<{ permissions: PermissionStore[] }>()
);

export const savePermissionsAction = createAction(
  '[Auth] Save Permissions',
  props<{ permissions: PermissionStore[] }>()
);

export const savedPermissionsAction = createAction(
  '[Auth] Saved Permissions'
);

export const loadPermissionsAction = createAction(
  '[Auth] Load Permissions'
);

export const loadPermissionsSuccessAction = createAction(
  '[Auth] Load Permissions Success',
  props<{ permissions: PermissionStore[] }>()
);

export const loadPermissionsFailureAction = createAction(
  '[Auth] Load Permissions Failure',
  props<{ error: string }>()
);

export const deletePermissionsAction = createAction(
  '[Auth] Delete Permissions'
);

export const deletedPermissionsAction = createAction(
  '[Auth] Deleted Permissions'
);
