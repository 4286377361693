import {isDevMode, NgModule} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {BrowserModule} from '@angular/platform-browser';
import {environment} from "../environments/environment";


//Local
import {AppComponent} from './app.component';
import {ModulesModule} from "./modules/modules.module";
import {AppRoutingModule} from "./app-routing.module";

//External
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";


//Azure
import {MsalGuard, MsalInterceptor, MsalModule} from "@azure/msal-angular";
import {PublicClientApplication} from "@azure/msal-browser";
import {azureConfig, azureGuardConfig, azureInterceptorConfig} from "./core/services/ms-auth.service";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpHandler} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {ApiService} from "./core/api/api.service";
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";
import {LayoutModule} from "./design/layout/layout.module";
import {ErrorUnautorizedInterceptor} from "./core/api/interceptors/error-unautorized.interceptor";
import {MapAuthorizationService} from "./core/services/map-authorization.service";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {appInitialState, appReducers} from "./redux/app.reducer";
import {AppStore} from "./redux/app.store";
import {MsalEffects} from "./redux/auth/msal/msal.effects";
import {EsmaxEffects} from "./redux/auth/esmax/esmax.effects";
import {PermissionsEffects} from "./redux/auth/permissions/permissions.effects";
import {UserEffects} from "./redux/auth/user/user.effects";


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ModulesModule,
    StoreModule.forRoot<AppStore>(appReducers, {
      initialState: appInitialState,
    }),
    EffectsModule.forRoot([
      MsalEffects,
      EsmaxEffects,
      PermissionsEffects,
      UserEffects
    ]),
    AppRoutingModule,
    RouterOutlet,
    LoggerModule.forRoot({
      level: (environment.production) ?
        NgxLoggerLevel.WARN :
        NgxLoggerLevel.INFO,
    }),
    MsalModule.forRoot(
      new PublicClientApplication(azureConfig),
      azureGuardConfig, azureInterceptorConfig
    ),
    BrowserAnimationsModule,
    HttpClientModule,
    ToastModule,
    LayoutModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
    }),
  ],
  providers: [
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {provide: HTTP_INTERCEPTORS, useClass: ErrorUnautorizedInterceptor, multi: true},
    MsalGuard,
    HttpClient,
    CookieService,
    MessageService,
    MapAuthorizationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
