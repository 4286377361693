import {Component, OnInit} from '@angular/core';
import {MsAuthService} from "../../../core/services/ms-auth.service";
import {CommonModule} from "@angular/common";
import {AuthorizationService} from "../../../core/services/authorization.service";
import {MessageService} from "primeng/api";
import {ToastModule} from "primeng/toast";
import {Store} from "@ngrx/store";
import {AppStore, TokenStore} from "../../../redux/app.store";
import * as msalActions from "../../../redux/auth/msal/msal.actions"
import {Observable} from "rxjs";
import {NotificationModel} from "../../../core/models/notification.model";
import {NotificationService} from "../../../core/services/notification.service";

@Component({
  standalone: true,
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
  imports: [
    CommonModule,
    ToastModule
  ],
  providers: [
    MessageService
  ]
})
export class LoginPageComponent implements OnInit{

  isLoading: boolean = false;

  msal$:Observable<TokenStore> = this.store.select(state => state.auth.msal);
  notificationEvent$:Observable<NotificationModel> = this.notificationService.getNotification();

  ngOnInit(): void {
    this.notificationEvent$.subscribe((notification: NotificationModel) => {
      if(notification) {
        this.isLoading = false;
      }
    });
  }

  constructor(
    private store: Store<AppStore>,
    private notificationService: NotificationService
  ) {
  }

  signUp() {
    this.isLoading = true;
    this.store.dispatch(msalActions.loginMsalAction());
  }

  logout() {
    this.store.dispatch(msalActions.logoutMsalAction());
  }
}
