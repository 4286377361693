import {LocaleSettings} from "primeng/calendar";


export const PRIMENG_DATE_ES: LocaleSettings = {
  firstDayOfWeek: 0,
  dayNames: [ "domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado" ],
  dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
  dayNamesMin: [ "D","L","M","X","J","V","S" ],
  monthNames: [ "enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre" ],
  monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
  today: 'Hoy',
  clear: 'Borrar',
  dateFormat: 'dd/mm/yy',
  weekHeader: 'Sm'
}

export const PRIMENG_CONFIG = {
  es: PRIMENG_DATE_ES
}
