import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as userActions from "../user/user.actions";
import {catchError, map, mergeMap, of, switchMap} from "rxjs";
import {AppStore, TokenStore, UserStore} from "../../app.store";
import {Store} from "@ngrx/store";
import {StorageService} from "../../../core/services/storage.service";

@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    private storageService: StorageService,
    private store: Store<AppStore>
  ) {
  }

  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.registerUserAction),
      switchMap(({user}) => {
        return [
          userActions.saveUserAction({user}),
        ];
      })
    ));

  saveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.saveUserAction),
      switchMap(({user}) => {
        this.storageService.save('user', user);
        return [
          userActions.savedUserAction(),
        ];
      })
    ));

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadUserAction),
      switchMap(() => {
        const user = this.storageService.get<UserStore>('user');
        if(user) {
          return [
            userActions.loadUserSuccessAction({user: user!}),
          ];
        }else{
          return [
            userActions.loadUserFailureAction({error: 'No se encontró el usuario'}),
          ];
        }
      })
    ));




}
