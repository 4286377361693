


export const ROUTER_COMPONENT = [
  {
    id: 49,
    component: () => import('src/app/design/views/products-and-stations/products-and-stations.component').then(c => c.ProductsAndStationsComponent),
  },
  {
    id: 51,
    component: () => import('src/app/design/views/group-stations/group-stations.component').then(c => c.GroupStationsComponent),
  },
  {
    id: 52,
    component: () => import('src/app/design/views/resellers/resellers.component').then(c => c.ResellersComponent),
  },
  {
    id: 59,
    component: () => import('src/app/design/views/panel-process/panel-process.component').then(c => c.PanelProcessComponent),
  },
  {
    id: 48,
    component: () => import('src/app/design/views/roles-and-permissions/roles-and-permissions.component').then(c => c.RolesAndPermissionsComponent),
  },
  {
    id: 61,
    component: () => import('src/app/design/views/dashboard-rp-com/dashboard-rp-com.component').then(c => c.DashboardRpComComponent),
  },
  {
    id:63,
    component: () => import('src/app/design/views/price-map/price-map.component').then(c => c.PriceMapComponent)
  },{
    id: 69,
    component: () => import('src/app/design/views/letters/letters.component').then(c => c.LettersComponent)
  },
  {
    id: 70,
    component: () => import('src/app/design/views/dashboard-reseller/dashboard-reseller.component').then(c => c.DashboardResellerComponent),
  },{
    id: 72,
    component: () => import('src/app/design/views/escalation/escalation.component').then(c => c.EscalationComponent)
  }

];
