import {Injectable} from '@angular/core';
import {LoginEntity} from "../api/entity/login.entity";
import {PermissionEntity} from "../api/entity/permission.entity";
import {MsAuthorizationModel} from "../models/ms-authorization.model";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {StorageService} from "./storage.service";
import {Observable, Subject} from "rxjs";
import {AppStore, TokenStore, UserStore} from "../../redux/app.store";
import {Store} from "@ngrx/store";
import {esmaxInitialState} from "../../redux/auth/esmax/esmax.reducer";
import {userInitialState} from "../../redux/auth/user/user.reducer";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private user: UserStore = userInitialState;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
    private store: Store<AppStore>
  ) {
    this.store.select(state => state.auth.user).subscribe((user) => this.user = user);
  }

  getUser(): UserStore {
    return this.user;
  }

  login$(msal: TokenStore){
    return this.http.post(environment.api.admin + "/Auth/Login", { tokenAD: msal.token }) as Observable<LoginEntity>;
  }

  goToDashboard(): void {
    this.router.navigate(['dashboard'], { replaceUrl: true });
  }

  goToLogin(): void {
    this.router.navigate(['/login']);
  }

  logout(): void {
    this.storageService.delete('esmax');
    this.storageService.delete('user');
    this.storageService.delete('permissions');
    this.goToLogin()
  }


}
