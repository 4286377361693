import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GraphsModule} from "./graphs/graphs.module";



@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule
  ]
})
export class ModulesModule { }
