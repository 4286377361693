import {createAction, props} from "@ngrx/store";
import {UserStore} from "../../app.store";


export const registerUserAction = createAction(
  '[Auth] Register User',
  props<{ user: UserStore }>()
);

export const saveUserAction = createAction(
  '[Auth] Save User',
  props<{ user: UserStore }>()
);

export const savedUserAction = createAction(
  '[Auth] Saved User'
);

export const loadUserAction = createAction(
  '[Auth] Load User'
);

export const loadUserSuccessAction = createAction(
  '[Auth] Load User Success',
  props<{ user: UserStore }>()
);

export const loadUserFailureAction = createAction(
  '[Auth] Load User Failure',
  props<{ error: string }>()
);

export const deleteUserAction = createAction(
  '[Auth] Delete User'
);

export const deletedUserAction = createAction(
  '[Auth] Deleted User'
);
