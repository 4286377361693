import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {NavigationService} from "./core/services/navigation.service";
import {NavigationEnd, Router, RouterEvent} from "@angular/router";
import {NotificationService} from "./core/services/notification.service";
import {Observable} from "rxjs";
import {NotificationModel} from "./core/models/notification.model";
import {MessageService} from "primeng/api";

import { PrimeNGConfig } from 'primeng/api';
import {PRIMENG_DATE_ES} from "./primeng.config";


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet> <p-toast></p-toast>'
})
export class AppComponent implements OnInit, OnDestroy {

  notifications: Observable<NotificationModel> = this.notificationService.getNotification();


  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private notificationService: NotificationService,
    private messageService: MessageService,
    private config: PrimeNGConfig
  ) {
    config.setTranslation({
      ...PRIMENG_DATE_ES
    });
  }

  ngOnInit(): void {
    this.notifications.subscribe((notification: NotificationModel) => {
      this.messageService.add({
        severity: notification.type,
        summary: notification.title,
        detail: notification.message
      })
    });
  }

  ngOnDestroy(): void {
    this.notifications.subscribe().unsubscribe();
  }

}
