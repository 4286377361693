import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarButtonComponent } from './topbar-button/topbar-button.component';
import { NavigationButtonComponent } from './navigation-button/navigation-button.component';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {MenuModule} from "primeng/menu";
import { ButtonExtraLargeComponent } from './button-extra-large/button-extra-large.component';
import { ButtonLargeComponent } from './button-large/button-large.component';
import {ButtonModule} from "primeng/button";
import {MenuVisibilityDirective} from "../../../core/directive/menu-visibility.directive";
import { ButtonSmallComponent } from './button-small/button-small.component';



@NgModule({
  declarations: [
    TopbarButtonComponent,
    NavigationButtonComponent,
    ButtonExtraLargeComponent,
    ButtonLargeComponent,
    ButtonSmallComponent
  ],
    imports: [
        CommonModule,
        OverlayPanelModule,
        MenuModule,
        ButtonModule
    ],
  exports: [
    TopbarButtonComponent,
    NavigationButtonComponent,
    ButtonExtraLargeComponent,
    ButtonLargeComponent,
    ButtonSmallComponent
  ]
})
export class ButtonsModule { }
