import { Component, OnInit } from '@angular/core';
import {TokenUpdater} from "../../../core/decorators/token-updater.decorator";
import {AuthorizationService} from "../../../core/services/authorization.service";
import {ApiService} from "../../../core/api/api.service";
import {LayoutModule} from "../../layout/layout.module";

@Component({
  standalone: true,
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  imports: [
    LayoutModule
  ]
})
export class DashboardComponent implements OnInit {

  constructor(
    private authorizationService: AuthorizationService,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    // this.apiService.token = this.authorizationService.getToken();
  }

}
