import {UserStore} from "../../app.store";
import {Action, createReducer, on} from "@ngrx/store";
import * as userActions from "./user.actions";


export const userInitialState: UserStore = {
  userId: '',
  name: '',
  urlAvatar: '',
  cargo: '',
  roles: [],
}

const reducer = createReducer(
  userInitialState,
  on(userActions.registerUserAction, (state, {user}) => user),
  on(userActions.saveUserAction, (state) => state),
  on(userActions.loadUserAction, (state) => state),
  on(userActions.loadUserSuccessAction, (state, {user}) => {
    return {
      ...state,
      ...((state.userId === '' || state.userId === null) ? user : state)
    }
  }),
  on(userActions.loadUserFailureAction, (state) => userInitialState),
  on(userActions.deleteUserAction, (state) => state),
  on(userActions.deletedUserAction, (state) => userInitialState),
);

export function userReducer(state: UserStore | undefined, action: Action) {
  return reducer(state, action);
}
