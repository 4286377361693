import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TopbarComponent } from './topbar/topbar.component';
import { DefaultPageComponent } from './default-page/default-page.component';

import { MegaMenuModule } from 'primeng/megamenu';
import {ButtonModule} from "primeng/button";
import {ButtonsModule} from "../components/buttons/buttons.module";
import { NavigationComponent } from './navigation/navigation.component';
import {MenuModule} from "primeng/menu";


@NgModule({
  declarations: [
    TopbarComponent,
    DefaultPageComponent,
    NavigationComponent
  ],
    imports: [
        CommonModule,
        MegaMenuModule,
        ButtonModule,
        ButtonsModule,
        MenuModule
    ],
  exports: [
    DefaultPageComponent
    ],
})
export class LayoutModule { }
