<div class="w-full topbar">
  <img src="assets/images/logo-esmax.png" class="mx-2 logo">
  <div class="flex justify-content-center w-full">
    <design-topbar-button *ngFor="let topbar_item of (topbarItems | async)" [parent]="topbar_item"></design-topbar-button>
  </div>
  <div class="flex justify-content-around align-items-center">
    <button class="user-flex m-0 p-0" (click)="menu.toggle($event)">
      <div class="user-flex">
        <div class="user-text">
          <div class="user-name">{{(account | async)?.name}}</div>
          <div class="user-station">{{(account | async)?.cargo}}</div>
        </div>
        <div class="user-image">
          <img [src]="(account | async)?.urlAvatar || account_image_default" height="32" alt="account_image">
        </div>
      </div>
    </button>
  </div>
  <p-menu #menu [model]="items" [popup]="true"></p-menu>
</div>


