import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {NotificationModel} from "../models/notification.model";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notification$ = new Subject<NotificationModel>();

  constructor() { }

  public getNotification() {
    return this.notification$.asObservable();
  }

  public setNotification(notification: NotificationModel) {
    this.notification$.next(notification);
  }


}
